import { useState, useEffect } from 'react';
import { customTableStyles } from '../utils/constants';
import { FaLayerGroup, FaChevronLeft } from "react-icons/fa6";
import { FaSearch } from "react-icons/fa";
import DataTable from 'react-data-table-component';
import { Row, Col, Container } from 'react-bootstrap';
import moment from 'moment';
import { Form, InputGroup } from 'react-bootstrap';

function StockList(props) {
  const [stockData, setStockData] = useState([]);
  const [stockQueryFilter, setStockQueryFilter] = useState({ fromDate: props.data.fromDate, toDate: props.data.toDate, txnFilter: props.data.txnFilter });

  const handleQuery = () => {
    if (stockQueryFilter.fromDate && stockQueryFilter.toDate) {
      //fetch the stock from the server using the stockQueryFilter object. pass the object as query params
      fetch(`/api/stocks?from_date=${stockQueryFilter.fromDate}&to_date=${stockQueryFilter.toDate}&txn_filter=${stockQueryFilter.txnFilter}`)
        .then((response) => response.json())
        .then((data) => {
          data = data.map((item, index) => {
            item.ob = item.ob != null ? item.ob : 0;
            item.issues = item.issues != null ? item.issues : 0;
            item.receipts = item.receipts != null ? item.receipts : 0;
            item.cb = item.ob + item.receipts - item.issues;
            return item;
          });
          setStockData(data);
        });
    }
  }

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    //set State variable for filter; from_date, to_date, 
    setStockQueryFilter({ ...stockQueryFilter, [name]: value });
  }

  const handleView = (e) => {
    console.log(e.id)

    props.view(e.id, stockQueryFilter);
    //props.view(selectedRows[0]);
  }

  const columns = [
    {
      name: 'Product Name',
      selector: row => row.product_name,
      sortable: true,
    },
    {
      name: 'Opening Balance',
      selector: row => row.ob,
      sortable: true,
    },
    {
      name: 'Inwards',
      selector: row => row.receipts,
    },
    {
      name: 'Outwards',
      selector: row => row.issues,
    },
    {
      name: 'Closing Balance',
      selector: row => row.cb,
    }
  ];

  const THeader = () => {
    return (
      <>
        <h4 className="mb-0 text-secondary"> <FaLayerGroup /> Stocks</h4>
      </>
    );
  }

  return (
    <Container fluid className='py-2'>
      <Row>
        <Col>
          <InputGroup className="py-2">
            <Form.Control name="fromDate" type="date" className="shadow-none" onChange={handleChange} value={stockQueryFilter.fromDate} />
            <Form.Control name="toDate" type="date" className="shadow-none" onChange={handleChange} value={stockQueryFilter.toDate} />
            <Form.Select name="txnFilter" className="shadow-none" onChange={handleChange} disabled>
              <option value="">-- All Transactions --</option>
              <option value="I">Issues</option>
              <option value="R">Receipts</option>
            </Form.Select>
            <button className="btn btn-light border" type="button" onClick={handleQuery}><FaSearch /></button>
          </InputGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="shadow">
            <DataTable
              title={<THeader />}
              columns={columns}
              data={stockData}
              customStyles={customTableStyles}
              pagination
              fixedHeader
              fixedHeaderScrollHeight="400px" //remove this if you dont need table scroll
              onRowClicked={(e) => handleView(e)}
              pointerOnHover //remove this if you dont need pointer while row hover
              className=" border z-10"
            />
          </div>
        </Col>
      </Row>
    </Container>
  );
}

function StockDetails(props) {

  const columns = [
    {
      name: 'Invoice No',
      selector: row => row.txn_no,
      sortable: true,
    },
    {
      name: 'Invoice Date',
      selector: row => moment(row.txn_date).format("DD-MM-YYYY"),
      sortable: true,
    },
    {
      name: 'Party',
      selector: row => row.org_name,
      sortable: true,
    },
    {
      name: 'Inwards',
      selector: row => row.inwards,
      sortable: true,
    },
    {
      name: 'Outwards',
      selector: row => row.outwards,
      sortable: true,
    }
  ];

  const [data, setData] = useState([]);

  useEffect(() => {
    fetch(`/api/stock-details?from_date=${props.data.stockQuery.fromDate}&to_date=${props.data.stockQuery.toDate}&pid=${props.data.selectedStock}`)
      .then((response) => response.json())
      .then((data) => {
        data = data.map((item, index) => {
          item.issues = item.issues != null ? item.issues : 0;
          item.receipts = item.receipts != null ? item.receipts : 0;
          return item;
        });
        setData(data);
      });
  }, []);
  
  const THeader = () => {
    return (
      <div className="d-flex align-items-center">
        <button type="button" className="btn btn-outline-secondary me-2" onClick={props.close}>
          <FaChevronLeft /> Back
        </button>
        <h4 className="mb-0 text-secondary">Transactions of {props.data.product_name}</h4>
      </div>
    );
  }

  return (
    <>
      <div className="my-2 block p-2">
        <div className="shadow">
          <DataTable
            title={<THeader />}
            columns={columns}
            data={data}
            customStyles={customTableStyles}
            pagination
            fixedHeader
            fixedHeaderScrollHeight="440px" //remove this if you dont need table scroll
            pointerOnHover //remove this if you dont need pointer while row hover
            className=" border z-10"
          />
        </div>
      </div>
    </>
  );
}

export { StockList, StockDetails };