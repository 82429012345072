import { useState, useEffect } from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { Form, ListGroup, Button, Modal } from 'react-bootstrap';
import { Table } from 'react-bootstrap';
import { FaCheck, FaXmark, FaPaperclip } from "react-icons/fa6";
import { updateData, postFile } from "./../utils/constants";
import moment from 'moment';
import { toast } from 'react-toastify';

//function to get the supply invoices from the database
function Receipts() {
  const [invoices, setInvoices] = useState([]);
  const [selectedInvoice, setSelectedInvoice] = useState(null);
  const [lineItems, setLineItems] = useState([]);
  const [attachments, setAttachments] = useState([]);
  const [showModal, setModalShow] = useState(false);
  const [isLoading, setLoading] = useState(true);

  const openModal = () => {
    setModalShow(true);
  }

  const getReceipts = async () => {
    try {
      const response = await fetch('/api/receipts');
      const json = await response.json();
      setInvoices(json.length ? json : []);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  const getReceiptItems = async (receiptId) => {
    try {
      const response = await fetch(`/api/receipt-items/${receiptId}`);
      const json = await response.json();
      console.log("receiptsItems: ", json);
      setLineItems(json.length ? json : []);
    } catch (error) {
      console.error(error);
    }
  }

  const getReceiptFiles = async (receiptId) => {
    try {
      const response = await fetch(`/api/receipt-files/${receiptId}`);
      const json = await response.json();
      console.log("receiptsFiles: ", json);
      setAttachments(json.length ? json : []);
    } catch (error) {
      console.error(error);
    }
  }

  const handleLineItemsChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    const index = parseInt(e.target.attributes.dataindex.value);

    const updatedLineItems = [...lineItems];
    updatedLineItems[index] = {
      ...lineItems[index],
      [name]: value
    };
    setLineItems(updatedLineItems);
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    const receiptData = { receipt: selectedInvoice, lineItems: lineItems };
    const productItems = lineItems.filter(e => e.product_id && e.units);
    if (productItems.length === 0) {
      alert("Line items should not be empty!");
      return false;
    }
    setLoading(true);
    if (selectedInvoice.id) {
      updateData('/api/receipt/'+selectedInvoice.id, receiptData)
      .then((response) => {
        toast.success(response.message);
        setSelectedInvoice(null);
        getReceipts();
      });
    }
  }

  const handleFileChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
  }

  const handleFileSubmit = (e) => {
    e.preventDefault();
    let files = document.getElementById("receipt-files").files;
    let receiptFields = document.getElementById("receipt-receipt-fields");
    receiptFields.disabled = true;
    let formData = new FormData();

    for (let i = 0; i < files.length; i++) {
      formData.append("files", files[i]);
    }

    if (selectedInvoice.id) {
      postFile(`/api/receipt-upload/${selectedInvoice.id}`, formData).then((response) => {
        toast.success(response.message);
        receiptFields.disabled = false;
      });
    } else {
      alert("You need to create the receipt first!");
    }
  }

  //function to select a supply invoice
  const selectReceiptInvoice = (invoice) => {
    setSelectedInvoice(invoice);
    getReceiptItems(invoice.id);
    getReceiptFiles(invoice.id);
  }

  useEffect(() => {
    //get the supply invoices from the database
    getReceipts([]);
  }, []);

  return (
    <Container fluid className="h-100 pt-2">
      {
        selectedInvoice ? (
          <Row xs={12} md={12} className="h-100">
            <Col xs={12} md={6} className="pt-2">
              <Card className="w-100 h-100 border-0 shadow">
                <Card.Header className="text-secondary fw-bold">
                  Receipt Information
                </Card.Header>
                <Card.Body>
                  <Row>
                    <Col>
                      <div>
                        <label className="form-label text-muted">Vendor Invoice No.</label>
                        <h6 className="fw-semibold px-1">{selectedInvoice.txn_no} </h6>
                      </div>
                    </Col>
                    <Col>
                      <div>
                        <label className="form-label text-muted">Vendor Invoice Date</label>
                        <h6 className="fw-semibold px-1">{moment(selectedInvoice.txn_date).format("DD-MM-YYYY")} </h6>
                      </div>
                    </Col>
                    <Col>
                      <div>
                        <label className="form-label text-muted">Warehouse</label>
                        <h6 className="fw-semibold px-1">{selectedInvoice.warehouse} </h6>
                      </div>
                    </Col>
                  </Row>
                  <Row className="my-1">
                    <Col xs={12} md={8}>
                      <div>
                        <label className="form-label text-muted">Vendor</label>
                        <h6 className="fw-semibold px-1">{selectedInvoice.vendor} </h6>
                      </div>
                    </Col>
                    <Col xs={12} md={4}>
                      <div className="pt-3">
                        <Button type="button" variant="secondary" size="sm" onClick={openModal}>
                          <FaPaperclip /> Attachments
                        </Button>
                      </div>
                    </Col>
                  </Row>
                  <form onSubmit={handleSubmit} encType="multipart/form-data">
                    <ListGroup variant="flush mt-2">
                      <ListGroup.Item className="bg-secondary text-light">
                        <Row xs={12} md={12}>
                          <Col xs={6} md={6} className="">Product Name</Col>
                          <Col xs={2} md={2} className="text-end">Quantity</Col>
                          <Col xs={2} md={2} className="text-end">Damaged</Col>
                          <Col xs={2} md={2} className="text-end">Missing</Col>
                        </Row>
                      </ListGroup.Item>
                      {lineItems.map((row, index) => (
                        <ListGroup.Item key={"RLI" + row.id} className="p-0">
                          <Row xs={12} md={12} className="g-0">
                            <Col xs={6} md={6}>
                              <h6 className="text-muted mb-0 mt-1 px-1">{row.product_name}</h6>
                            </Col>
                            <Col xs={2} md={2}>
                              <Form.Control type="number" name="units" size="sm" className="shadow-none rounded-0 text-end" dataindex={index} value={row.units} onChange={handleLineItemsChange} />
                            </Col>
                            <Col xs={2} md={2}>
                              <Form.Control type="number" name="damaged" size="sm" className="shadow-none rounded-0 text-end" dataindex={index} value={row.damaged} onChange={handleLineItemsChange} />
                            </Col>
                            <Col xs={2} md={2}>
                              <Form.Control type="number" name="missing" size="sm" className="shadow-none rounded-0 text-end" dataindex={index} value={row.missing} onChange={handleLineItemsChange} />
                            </Col>
                          </Row>
                        </ListGroup.Item>
                      ))}
                    </ListGroup>
                    {!selectedInvoice.is_approved && 
                      <Button type="submit" variant="primary" className="m-2">
                        <FaCheck /> Approve Invoice
                      </Button>
                    }
                    <Button variant="secondary" className="m-2" onClick={() => setSelectedInvoice(null)}>
                      <FaXmark className="mb-1" /> Cancel
                    </Button>
                  </form>
                </Card.Body>
              </Card>
              <Modal
                size="lg"
                show={showModal}
                onHide={() => setModalShow(false)}
                aria-labelledby="receipt-attachment"
              >
                <Modal.Header closeButton>
                  <Modal.Title id="receipt-attachment">
                    Vendor Invoice
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  {
                    attachments.map((row, index) =>
                      <embed key={"RFA" + row.id} src={row.file_path} width="100%" height="auto" />
                    )
                  }
                </Modal.Body>
              </Modal>
            </Col>
            <Col xs={12} md={6} className="pt-2">
              <Card className="w-100 h-100 border-0 shadow">
                <Card.Header className="text-secondary fw-bold">
                  Upload Transport Receipt Image
                </Card.Header>
                <Card.Body className="overflow-y-scroll" style={{ height: '80vh' }}>
                  <form id="order-form" encType="multipart/form-data" method="POST" action="/process-excel-upload">
                    <fieldset id="order-fields" className="my-0 mx-auto">
                      <div className="mb-3">
                        <p className="text-muted fw-light">File must be in (.png / .jpg / .jpeg) format</p>
                        <input type="file" id="order-sheet" className="form-control" required />
                      </div>
                      <div className="my-2">
                        <input type="submit" className="btn btn-primary fw-bold" value="Upload and Update" />
                      </div>
                    </fieldset>
                  </form>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        ) : (
          <>
            <Container fluid className="bg-white shadow">
              <h4 className="text-secondary fw-bold py-2">Inwards</h4>                
              <div className="overflow-y-scroll" style={{maxHeight:'80vh'}}>
                <Table responsive bordered hover className="border-dark">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Invoice No</th>
                      <th>Invoice Date</th>
                      <th>Vendor</th>
                      <th>Transport Receipt No</th>
                      <th>Transport Receipt Date</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody className="mouse-pointer">
                    {invoices.map((invoice, index) => (
                      <tr key={"R" + invoice.id} onClick={() => selectReceiptInvoice(invoice)}>
                        <td>{index + 1}</td>
                        <td>{invoice.txn_no}</td>
                        <td>{moment(invoice.txn_date).format("DD-MM-YYYY")}</td>
                        <td>{invoice.vendor}</td>
                        <td>{invoice.tr_no}</td>
                        <td>{moment(invoice.tr_date).format("DD-MM-YYYY")}</td>
                        <td>{invoice.is_approved?"Approved":"Pending / Cancelled"}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </Container>
          </>
        )
      }
    </Container>
  );
}

export default Receipts;
