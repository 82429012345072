import { useEffect, useState } from 'react';
import { postFile } from '../utils/constants';
import { Button, Card, Col, Container, Form, InputGroup, ListGroup, Row, Tabs, Tab, Table, Spinner } from 'react-bootstrap';
import { FaPlus, FaPencil, FaXmark, FaFileExcel, FaTrashCan, FaPenToSquare, FaLayerGroup, FaChevronLeft } from "react-icons/fa6";
import ImportFile from '../downloads/asset_mapping.xlsx';
import moment from 'moment/moment';
import { toast } from 'react-toastify';

export default function AssetMapping() {
  const [assets, setAssets] = useState([]);
  const [key, setKey] = useState('history');
  const [assetUploadHistoryFiles, setUploadAssetHistoryFiles] = useState([]);
  const [assetUploadHistory, setAssetUploadHistory] = useState([]);

  const handleFileChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setAssets({ ...assets, [name]: value });
    console.log(assets);
  }

  //fetch asset history files list and update the state variable
  const getAssetUploadHistory = () => {
    fetch('/api/asset-upload-history')
      .then((response) => response.json())
      .then((data) => {
        setUploadAssetHistoryFiles(data);
      });
  }

  //function to return options for the select dropdown from the state variable
  const getAssetUploadHistoryOptions = () => {
    return assetUploadHistoryFiles.map((file) => {
      return (
        <option key={file.file_name}>{file.file_name}</option>
      )
    })
  }

  const fetchAssetUploadHistory = (e) => {
    const fileName = e.target.value;
    fetch(`/api/asset-upload-history/${fileName}`)
      .then((response) => response.json())
      .then((data) => {
        //set the state variable to the data
        setAssetUploadHistory(data);
      });
  }

  //on component mount, fetch the asset history files list
  useEffect(() => {
    getAssetUploadHistory();
  }, []);

  const handleFileSubmit = (e) => {
    e.preventDefault();
    let files = document.getElementById("asset-sheet").files;
    let assetSubmit = document.getElementById("asset-submit");
    assetSubmit.disabled = true;
    let formData = new FormData();

    for (let i = 0; i < files.length; i++) {
      formData.append("files", files[i]);
      formData.append("file_name", `${files[i].name}-${moment().format("DD-MM-YYYY HH:mm:ss")}`);
    }

    if (files.length) {
      postFile(`/api/asset-upload`, formData).then((response) => {
        toast.success(response.message);
        assetSubmit.disabled = false;
        //clear the file input
        document.getElementById("asset-sheet").value = "";
        //show the success message using toast
        toast.success("Asset mapping uploaded successfully!");

        getAssetUploadHistory();
      });
    } else {
      alert("Select a xlsx file first!");
    }
  }

  return (
    <Container fluid className="bg-white mt-2 h-100">
      <h4 className="fw-semibold text-secondary py-2">
        Asset Mapping
      </h4>
      <Tabs
        id="asset_mapping_tab"
        activeKey={key}
        onSelect={(k) => setKey(k)}
      >
        <Tab eventKey="history" title="History">
          {/* insert a select dropdown here to select the file name */}
          <Row className="my-3">
            <Col md="4">
              <Form.Select aria-label="Select file to retrieve asset mappings"
                onChange={fetchAssetUploadHistory}
              >
                <option>Select file name</option>
                {getAssetUploadHistoryOptions()}

              </Form.Select>
            </Col>
          </Row>
          <div className="overflow-y-scroll" style={{maxHeight:'80vh'}}>
            <Table responsive bordered hover className="border-dark scrollable-table overflow-y-scroll">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Order Primary No</th>
                  <th>Motor Serial No</th>
                  <th>Pump Serial No</th>
                  <th>Controller Serial No</th>
                  <th>PV Serial No</th>
                  <th>Created Time</th>
                </tr>
              </thead>
              <tbody>
                {/* Map through your state variable here to render the history */}
                {assetUploadHistory.map((history, index) => {
                  return (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{history.primary_no}</td>
                      <td>{history.motor_sr_no}</td>
                      <td>{history.pump_head_sr_no}</td>
                      <td>{history.controller_sr_no}</td>
                      <td>{history.pv_sr_no}</td>
                      <td>{moment(history.created_at).format("DD-MM-YYYY HH:mm:ss")}</td>
                    </tr>
                  )
                })}
              </tbody>
            </Table>
          </div>
        </Tab>
        <Tab eventKey="upload" title="Upload">
          <Row xs={12} md={12} className="h-100 w-100">
            <Col xs={12} sm={6} md={6} className="p-2">
              <Card className="w-100 border-0 shadow">

                <Card.Body>
                  <Form onSubmit={handleFileSubmit}>
                    <fieldset id="asset-fields" className="my-0 mx-auto">
                      <div className="mb-3">
                        <p className="text-muted fw-light">File must be in xlsx format</p>
                        <input type="file" id="asset-sheet" className="form-control" onChange={handleFileChange} required />
                      </div>
                    </fieldset>
                    <Row>
                      <Col>
                        <input id="asset-submit" type="submit"
                          className="btn btn-outline-primary fw-bold"
                          value="Upload and Update" />
                      </Col>
                      <Col>
                        <a href={ImportFile}>
                          <FaFileExcel className="mb-1" /> Download template
                        </a>
                      </Col>
                    </Row>
                  </Form>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Tab>
      </Tabs>
    </Container>
  )
}