import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import { useEffect, useMemo, useReducer, useState } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { TopNav } from './partials/layouts';
import { Login } from './screens/Login';
import Dashboard from './screens/Dashboard';
import Issues from './screens/Issues';
import OrderStatus from './screens/OrderStatus';
import StockInformation from './screens/StockInformation';
import Receipts from './screens/Receipts';
import AssetMapping from './screens/AssetMapping';
import { ToastContainer, toast } from 'react-toastify';
import { AuthContext } from './utils/constants';

function App() {  
  const [state, dispatch] = useReducer(
    (prevState, action) => {
      switch (action.type) {
        case 'RESTORE_TOKEN':
          return {
            ...prevState,
            userToken: action.token,
            isLoading: false,
          };
        case 'SIGN_IN':
          return {
            ...prevState,
            isSignout: false,
            userToken: action.token,
          };
        case 'SIGN_OUT':
          return {
            ...prevState,
            isSignout: true,
            userToken: null,
          };
      }
    },
    {
      isLoading: true,
      isSignout: false,
      userToken: null,
    }
  );
  
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch the token from storage then navigate to our appropriate place
    const bootstrapAsync = async () => {
      let userToken;

      try {
        userToken = await sessionStorage.getItem('userToken');
      } catch (e) {
        console.log(e);
      } finally {
        dispatch({ type: 'RESTORE_TOKEN', token: userToken });
      }
    };
    bootstrapAsync();
  }, []);

  const authContext = useMemo(
    () => ({
      signIn: async (data) => {
        fetch('/api/login', {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            'username' :data.username,
            'password' :data.password
          })
        })
        .then(response => response.json())
        .then(result => {
          sessionStorage.setItem("userName", result.username);
          sessionStorage.setItem("userToken", result.email);
          dispatch({ type: 'SIGN_IN', token: result.email });
          toast.default("Welcome ...");          
        })
        .catch((e) => {
          toast.error(e);
          console.error('Error:', e);
        });
      },
      signOut: async() => {
        sessionStorage.removeItem("userToken")
        dispatch({ type: 'SIGN_OUT', token: null});
      },
      signUp: async (data) => {
        // In a production app, we need to send user data to server and get a token
        // We will also need to handle errors if sign up failed
        // After getting token, we need to persist the token using `SessionStorage`
        // In the example, we'll use a dummy token
        dispatch({ type: 'SIGN_IN', token: 'dummy-auth-token' });
      },
    }),
    []
  );

  return (
    <AuthContext.Provider value={authContext}>
      <div className="App h-100">
        {state.isLoading?(
          <Routes>
              <Route path="/login" element={<Login />} />
          </Routes>
        ):state.userToken===null?(
          <Routes>              
              <Route path="*" element={<Login />} />              
          </Routes>
        ):(
          <div id="main-body">
            <TopNav />        
            <main id="Content" className="container-fluid h-100">
              <ToastContainer />                          
              <Routes>
                <Route path="/" exact element={<Dashboard />} />
                <Route path="/outwards" element={<Issues />} />
                <Route path="/order-status" element={<OrderStatus />} />
                <Route path="/stock-information" element={<StockInformation />} />
                <Route path="/inwards" element={<Receipts />} />
                <Route path="/asset-mapping" element={<AssetMapping />} />
                {/* Add more routes for other functionalities */}
              </Routes>
            </main>               
          </div>
        )}
      </div>
    </AuthContext.Provider>
  );
}

export default App;

