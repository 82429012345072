import React from 'react';

function StockInformation() {
  return (
    <div className="h-100 d-flex justify-content-center align-items-center">
      <h2 className="text-secondary">Stock Information Page</h2>
      {/* Add content for the Overview page */}
    </div>
  );
}

export default StockInformation;
