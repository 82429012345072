import { Component } from 'react';
import { StockList, StockDetails } from './Stocks';
import moment from 'moment/moment';

class Dashboard extends Component {
  constructor(props){
    super(props);
    this.state = {
      data : [],      
      isLoading : false,
      selectedStock : '',
      show : false,    
      stockQuery : {
        fromDate: moment().format(moment.HTML5_FMT.DATE), 
        toDate: moment().format(moment.HTML5_FMT.DATE),
        txnFilter : ''
      }
    };
    this.view = this.view.bind(this);
  }

  view(selectedStock, stockQuery) {        
    this.setState({ selectedStock, stockQuery });
    this.setState({ show: true });
  }    

  render() {
    if(!this.state.isLoading) {
      return(
        <>
          {!this.state.show?(
            <StockList data={this.state.stockQuery} view={this.view} />  
            ):(
            <StockDetails data={this.state} close={()=> this.setState({show: false})} />
          )}
        </>
      );
    }
    else {
      return(
        <>Loading...</>
      );
    }
  }
}

export default Dashboard;