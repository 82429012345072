import { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { AuthContext } from '../utils/constants';

function Login() {
  const { signIn } = useContext(AuthContext);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const handleSubmit = (e)=> {
    e.preventDefault();
    signIn({ username: username, password: password });    
  }

  return(
    <>
      <div id="login-container" className="bg-secondary bg-gradient h-100">
        <div id="login-area" className="d-flex justify-content-center align-items-center h-100">          
          <div id="login-main" className="bg-light p-5 border rounded shadow w-50">
            <form id="login-form" onSubmit={handleSubmit} autoComplete="off">
              <div className="mb-2 d-flex justify-content-center align-items-center">
                <img className="img-fluid" src="./logo.jpg" />
              </div>
              <h4 className="text-center my-3 text-secondary fw-bold">Warehouse Login</h4>
              <div className="mb-3">
                <label htmlFor="exampleInputEmail1" className="form-label">Email address</label>
                <input type="email" className="form-control" id="exampleInputEmail1" value={username} onChange={(e)=>setUsername(e.target.value)} aria-describedby="emailHelp" required />
                <div id="emailHelp" className="form-text">We'll never share your email with anyone else.</div>
              </div>
              <div className="mb-3">
                <label htmlFor="exampleInputPassword1" className="form-label">Password</label>
                <input type="password" className="form-control" id="exampleInputPassword1" value={password} onChange={(e)=>setPassword(e.target.value)} required />
              </div>
              <div className="mb-3 form-check">
                <input type="checkbox" className="form-check-input" id="exampleCheck1" />
                <label className="form-check-label" htmlFor="exampleCheck1">Remember me</label>
              </div>
              <div className="mb-3 text-center">
                <Link className="text-muted fw-semibold" to="#">Forget Password?</Link>
              </div>
              <div className="text-center">
                <button type="submit" className="btn btn-primary mt-1">Submit</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export { Login };
