import React from 'react';

function OrderStatus() {
  return (
    <div className="h-100 d-flex justify-content-center align-items-center">              
      {/* Add content for the Order Status page */}     
    
      {/* Upload Excel File Form  */}     
      <div className="card w-50 order-form p-3 bg-white shadow rounded border-0">
        <h4 className="mb-3 fw-bold text-muted"> Bulk Order Status Update </h4>
        <h4 className="mb-3 fw-semibold text-secondary"> Upload Excel File </h4>
        <form id="order-form" encType="multipart/form-data" method="POST" action="/process-excel-upload">
          <fieldset id="order-fields" className="my-0 mx-auto"> 
            <div className="mb-3">
              <p className="text-muted fw-light">File must be in (.xlsx) or (.csv) format</p>  
              <label htmlFor="title">Order File</label>
              <input type="file" id="order-sheet" className="form-control" required /> 
            </div>
            <div className="my-2">
              <input type="submit" className="btn btn-primary fw-bold" value="Upload and Update" />
              <button type="button" className="btn btn-outline-secondary fw-bold ms-3">Cancel</button>
            </div>
          </fieldset>
        </form>        
      </div> 
    </div>
  );
}

export default OrderStatus;
